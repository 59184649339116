class BasketItem{
	constructor(id,cnt,on,price1,price2,price3,price4) {
		this.id = id;
		this.cnt = cnt;
		this.on = on;
		this.price1 = price1;
		this.price2 = price2;
		this.price3 = price3;
		this.price4 = price4;
		this._price = null;
	}
	get price() { // Геттер для свойства price
		if (this.cnt > 0 && this.cnt < 10) {
			this._price = this.price1;
		} else if (this.cnt > 9 && this.cnt < 50) {
			this._price = this.price2;
		} else if (this.cnt > 49 && this.cnt < 100) {
			this._price = this.price3;
		} else if (this.cnt > 99) {
			this._price = this.price4;
		}
		return this._price;
	}
	set price(value) { // Сеттер для свойства price
		this._price = value;
	}
}

function basket_item_delete(id){
	loadBasket(Number(id), 0);
}

function basket_item_minus(id){
	loadBasket(Number(id), -1);
}

function basket_item_plus(id){
	loadBasket(Number(id), 1);
}

function getBasketFromStorage(){
	let jsonStr;
	if(jsonStr = sessionStorage.getItem("basket")){
		document.basket = new Array();
		const arrData = JSON.parse(jsonStr);
		arrData.forEach(e => document.basket.push(new BasketItem(Number(e.id), Number(e.cnt), e.on, Number(e.price1), Number(e.price2), Number(e.price3), Number(e.price4))));
	}else{
		document.basket = new Array();
	}
}

export function countBasket(){
	

	const sum = document.basket.reduce((partialSum, a) => partialSum + a.cnt*a.price, 0);
	sessionStorage.setItem('basket_sum',sum);
	document.getElementById('basket-header-text').innerHTML = `${document.basket.length} (${sum}руб.)`;
	const myUl = document.createElement('div');
	myUl.id = 'basket-list';
	myUl.classList.add('basket-list');
	const noChange = window.location.href.toLowerCase().includes('order2');
	document.basket.forEach(dt => {
		let myLi = document.createElement('div');
		myLi.classList.add('basket-list-line');
		myLi.innerHTML = noChange?`<div class='basket-name'>${dt.on}</div>
							<div class='basket-count'><p>&nbsp;&nbsp;${dt.cnt}&nbsp;&nbsp;</p><p>&nbsp;&nbsp;x</p></div>
							<div class='basket-price'><p> ${dt.price}руб.</p></div>`
							:`<div class='basket-name'><a href='index.html?id=${dt.id}'>${dt.on}</a></div>
							<div class='basket-count'>
								<button type='button' class='basket-btn-i' title='меньше' id='minus${dt.id}'>-</button>
									<p>&nbsp;&nbsp;${dt.cnt}&nbsp;&nbsp;</p>
								<button type='button' class='basket-btn-i' title='больше' id='plus${dt.id}'>+</button>
								<p>&nbsp;&nbsp;x</p>
							</div>
							<div class='basket-price'><p> ${dt.price}р.</p> 
							<button type='button' class='basket-btn-i' title='Удалить' id='delete${dt.id}'>x</button></div>`;
		myUl.appendChild(myLi);
	});
	
	
	document.getElementById('basket-body').innerHTML = '';
	document.getElementById('basket-body').appendChild(myUl);
	//let strurl = window.location.href;
	if(window.location.href.toLowerCase().indexOf('order') < 0){
		if(document.basket.length > 0){
			const buttonOrder = document.createElement('button');
				buttonOrder.type = 'button';
				buttonOrder.classList.add('basket-btn-order');
				buttonOrder.innerText = 'Оформить заказ';
				buttonOrder.onclick = function(){   window.location.href='order1.html';};
				myUl.appendChild(buttonOrder);
		}else{
			document.getElementById('basket-body').innerHTML = '<div>Здесь пока пусто!</div>';
			document.getElementById('basket-header-text').innerHTML = '0 (0р.)';
		}
	}
	
	/* if(document.getElementById('basket').classList.contains('active')){
		let height1 = 90 + myUl.scrollHeight;
		if(window.location.href.toLowerCase().indexOf('order') >= 0){ height1 -= 40; }
		let height2 = 40 + myUl.scrollHeight;
		document.getElementById('basket').setAttribute('style','height: '+ height1 + 'px;');
		document.getElementById('basket-body').setAttribute('style','height: '+ height2 + 'px;');
	}else{
		let height1 = 36;
		let height2 = 0;
		document.getElementById('basket').setAttribute('style','height: '+ height1 + 'px;');
		document.getElementById('basket-body').setAttribute('style','height: '+ height2 + 'px;');
	} */
}

export const loadBasket = (id, add_cnt, on, price1, price2, price3, price4) => {
	
	getBasketFromStorage();
	if(id){
		const i = document.basket.findIndex(product => product.id === id);
		if(i >= 0){
			document.basket[i].cnt += add_cnt;
			if(add_cnt == 0 || document.basket[i].cnt <= 0 ){
				document.basket.splice(i, 1);
			}
		}else{
			if(add_cnt>0)
				document.basket.push(new BasketItem(id, add_cnt, on, price1, price2, price3, price4));
		}
		
		sessionStorage.setItem("basket", JSON.stringify(document.basket));
		getBasketFromStorage();
	}
	countBasket();
	let event = new Event("basket_loaded", {bubbles: true}); // (2)
	document.getElementById('basket-header-text').dispatchEvent(event);
}

export function createBasketListeners(){
	document.addEventListener('click', function(event) {
		//Раскрытие закрытие корзины
		if(event.target.id.includes('basket-header')){
			document.getElementById('basket').classList.toggle('active');
			document.getElementById('basket-header').classList.toggle('active');
			document.getElementById('basket-body').classList.toggle('active');
			let txt = document.getElementById('basket-header-check').innerHTML;
			document.getElementById('basket-header-check').innerHTML = (txt === '◀')?'▼': '&#9664;';
			countBasket();
		}
		if(event.target.id.includes('minus')){
			event.preventDefault();
			const id = event.target.id.slice(5);
			basket_item_minus(id);
			return false;
		}
		if(event.target.id.includes('plus')){
			event.preventDefault();
			const id = event.target.id.slice(4);
			basket_item_plus(id);
			return false;
		}
		if(event.target.id.includes('delete')){
			event.preventDefault();
			const id = event.target.id.slice(6);
			basket_item_delete(id);
			return false;
		}
	});
}