export function navbar(page, razdel = false){
	const ol = document.createElement('ol');
	const li1 = document.createElement('li');
	li1.innerHTML = `<a href='index.html' title='Домой'><span class='icon-home'>⌂</span></a>`;
	//<div class='svg'><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="m11.4118 2.19126c.3507-.25501.8257-.25501 1.1764 0l11 8.00004c.3503.2547.4967.706.3628 1.1179-.134.412-.5179.6908-.951.6908h-2v9c0 .5523-.4477 1-1 1h-16c-.55229 0-1-.4477-1-1v-9h-2.000001c-.433138 0-.817037-.2788-.9509823-.6908-.1339454-.4119.0125165-.8632.3628113-1.1179zm-7.3401 7.81124c.51882.0368.9283.4693.9283.9975v9h14v-9c0-.5282.4095-.9607.9283-.9975l-7.9283-5.766z" fill="rgb(0,0,0)" style="fill: rgb(65, 105, 255);"></path></svg></div></a>`;
	ol.appendChild(li1);
	if(razdel){
		const li2 = document.createElement('li');
		li2.innerHTML = `<a href='${razdel.link}'>${razdel.name}</a>`;
		ol.appendChild(li2);
	}
	const li3 = document.createElement('li');
	li3.innerHTML = page;
	ol.appendChild(li3);
	document.getElementsByClassName('navbar')[0].appendChild(ol);
}