import './styles/index.scss'
//import data from './data.json'
import {asyncData} from './asyncdata.js'
import {loadBasket, countBasket, createBasketListeners} from './basket.js';
import { navbar } from './navbar.js';

console.log('Это загрузилось')

function getData(id,tp){
	if(id){
		const ids = id.split(':');
		if(ids.length > 0){
			return data.filter((el) => ids.includes(el.id.toString()));
		}
	}
	if(tp){
		return data.filter((el) => tp.indexOf(el.tp) >= 0);
	}
}

function createDivForEachData(element_data, index, array) {
  
  // Создаем новый элемент div
  const newDiv = document.createElement("div");

  // Устанавливаем стиль для нового элемента div
  newDiv.setAttribute("id", element_data['id']);
  newDiv.setAttribute("class", "product-card");
  
  const myName = document.createElement('div');
  myName.setAttribute("class", "product-name");
  myName.innerHTML = "<a href='?id="+element_data['id']+"'>"+element_data['on']+"</a>";
  myName.id = 'on' + element_data['id'];
  newDiv.appendChild(myName);
  
  const myImage = document.createElement('a');
  myImage.setAttribute("href", "?id="+element_data['id']);
  myImage.setAttribute("class", "aimg");
  
  myImage.innerHTML = "<img src='https://www.domtest.ru/images/" + (element_data['im'].split(';')[0] || "logo-kreativmp.gif") + "' class='product-image' alt='"+element_data['on']+"'>";
  newDiv.appendChild(myImage);
  
  const myDescription = document.createElement('div');
  myDescription.setAttribute("class", "product-description");
  myDescription.innerText = element_data['text'];
  newDiv.appendChild(myDescription);
  
  const myPrice = document.createElement('div');
  myPrice.setAttribute("class", "product-price");
  myPrice.innerText = element_data['price1'] + ' руб.';
  newDiv.appendChild(myPrice);
  
  //newDiv.appendChild(document.createTextNode(element_data['text']));
 // newDiv.appendChild(document.createTextNode(element_data['price1'] + ' руб.'));
  const myPrice1 = document.createElement('input');
	myPrice1.type = 'hidden';
	myPrice1.value = element_data['price1'];
	myPrice1.id = 'price1-' + element_data['id'];
	newDiv.appendChild(myPrice1);
  const myPrice2 = document.createElement('input');
	myPrice2.type = 'Hidden';
	myPrice2.value = element_data['price2'];
	myPrice2.id = 'price2-' + element_data['id'];
	newDiv.appendChild(myPrice2);
  const myPrice3 = document.createElement('input');
	myPrice3.type = 'Hidden';
	myPrice3.value = element_data['price3'];
	myPrice3.id = 'price3-' + element_data['id'];
	newDiv.appendChild(myPrice3);
  const myPrice4 = document.createElement('input');
	myPrice4.type = 'Hidden';
	myPrice4.value = element_data['price4'];
	myPrice4.id = 'price4-' + element_data['id'];
	newDiv.appendChild(myPrice4);
  
  const myDivB = document.createElement('div');
  myDivB.setAttribute("class", "product-divb");
  
	  const myInput = document.createElement('input');
		myInput.type = 'text';
		myInput.value = 1;
		myInput.id = 'input-' + element_data['id'];
		myInput.setAttribute('maxlength', '4');
		myInput.setAttribute('size', '2');
		myDivB.appendChild(myInput);
	  
	  const mySpan = document.createElement('span');
		mySpan.setAttribute("class", "product-span");
		mySpan.innerText = ' шт. ';
		myDivB.appendChild(mySpan);
		
	  const myButton = document.createElement('input');
		myButton.type = 'button';
		myButton.classList.add('cart-button');
		myButton.value = 'Заказать';
		myButton.setAttribute('data-addtobasketbutton',element_data['id']);
		myDivB.appendChild(myButton);
	
  newDiv.appendChild(myDivB);
  
	const myCheckMark = document.createElement('div');
			myCheckMark.classList.add('check-mark');
			//myCheckMark.addEventListener('basket_loaded', showCheckMarks(element_data['id']));
			myCheckMark.setAttribute('data-id',element_data['id']);
			showChecks(myCheckMark);
			newDiv.appendChild(myCheckMark);

  // Добавляем новый элемент div в документ
  const container = document.getElementById('body_content');
  container.appendChild(newDiv);
}


const loadData = () => {
	
	loadBasket();
	const params = new Proxy(new URLSearchParams(window.location.search), {
		get: (searchParams, prop) => searchParams.get(prop),
	});
	
		let id = (params.id)?params.id:''; 
		let tp = (params.tp)?params.tp:'';
		createMainPage(id,tp);
	
	createListeners();
	createBasketListeners();

	document.dispatchEvent(new Event("kategoria-achieved"));
}


	
const createMainPage = (id, tp) => {
	
	if(!id && !tp){
		id = '2:6:7:8:48:63:60:67:33:34:55';  // то что будет на главной странице
		document.title = "DomTest.Ru Диагностические экспресс тесты";
		document.querySelector('meta[name="description"]').setAttribute("content", "Интернет магазин экспресс тестов для диагностики инфекций, выявления кардио маркеров и наркотиков.");
		document.querySelector('meta[name="keywords"]').setAttribute("content", "тесты купить домтест креатив наркотики инфекции кардио инфаркт");
		document.kategoria = '';
	}else if(tp){
		switch(tp){
			case 'n':
				document.title = "Тесты на наркотики";
				document.querySelector('meta[name="description"]').setAttribute("content", "Список тестов на наркотики");
				document.querySelector('meta[name="keywords"]').setAttribute("content", "тесты, купить, креативмп");
				document.getElementById('header_title').innerHTML = '<h1>Выявление наркотиков</h1>';
				document.kategoria = 'narco';
				navbar('Наркотики');
				break;
			case 'zpqe':
				document.title = "Тесты на инфекции";
				document.querySelector('meta[name="description"]').setAttribute("content", "Список тестов на инфекции");
				document.querySelector('meta[name="keywords"]').setAttribute("content", "тесты, купить, инфекции");
				document.getElementById('header_title').innerHTML = '<h1>Диагностика инфекций</h1>';
				document.kategoria = 'infect';
				navbar('Инфекции');
				break;
			case 'kce':
				document.title = "Тесты на кардиомаркеры, инфаркт";
				document.querySelector('meta[name="description"]').setAttribute("content", "Список тестов на кардиомаркеры, инфаркт");
				document.querySelector('meta[name="keywords"]').setAttribute("content", "тесты, купить, кардиомаркеры, инфаркт, тропонин, миоглобин");
				document.getElementById('header_title').innerHTML = '<h1>Диагностика инфаркта</h1>';
				document.kategoria = 'cardio';
				navbar('Кардиомаркеры');
				break;
			case 'e':
				document.title = "Расходные материалы";
				document.querySelector('meta[name="description"]').setAttribute("content", "Дополнительные средства для проведения теста.");
				document.querySelector('meta[name="keywords"]').setAttribute("content", "спиртовые салфетки, скарификаторы, ланцеты");
				document.getElementById('header_title').innerHTML = '<h1>Дополнительные средства</h1>';
				document.kategoria = 'additions';
				navbar('Доп. средства');
				break;
			default:
				document.title = `Экспресс тесты ${tp}`;
				document.querySelector('meta[name="description"]').setAttribute("content", `Экспресс тесты ${tp}`);
				//document.querySelector('meta[name="keywords"]').setAttribute("content", "");
				document.getElementById('header_title').innerHTML = `<h1>Экспресс тесты ${tp}</h1>`;
				document.kategoria = '';
				navbar(`Экспресс тесты ${tp}`);
		}
	}
	let pageData = getData(id,tp);
	console.log(pageData);
	if(pageData.length === 1){
		createFullCard(pageData[0]);
	}else if(pageData.length > 1){
		pageData.forEach(createDivForEachData);
	}
	//const myAsync = new asyncData(`https://www.domtest.ru/request_data.php?id=${id}&tp=${tp}`);
	//myAsync.after_ok = (data) => {
	//	if(data.length > 1)
	//		data.forEach(createDivForEachData);
	//	else if (data.length === 1)
	//		createFullCard(data[0]);
	//};
	//myAsync.after_error = (error) => {
	//									document.basket = new Array({id:0, cnt:0,price:0,on:error.message});
	//									countBasket();
	//								}
	//myAsync.doit();
	
}

const createFullCard = (d) => {
	
	document.title = "DomTest.Ru "+d.on;
	document.getElementById('header_title').innerHTML = `<h1>${d.on}</h1>`;
	document.querySelector('meta[name="description"]').setAttribute("content", d.ds);
	document.querySelector('meta[name="keywords"]').setAttribute("content", d.kw);
	switch(d.tp){
		case 'n':
			document.kategoria = 'narco';
			navbar(d.on,{name: 'Наркотики', link: '?tp=n'});
			break;
		case 'z':
		case 'p':
		case 'q':
			document.kategoria = 'infect';
			navbar(d.on,{name: 'Инфекции', link: '?tp=zpqe'});
			break;
		case 'k':
		case 'c':
			document.kategoria = 'cardio';
			navbar(d.on,{name: 'Кардиоматкеры', link: '?tp=kce'});
			break;
		case 'e':
			document.kategoria = 'additions';
			navbar(d.on,{name: 'Доп.средства', link: '?tp=e'});
			break;
	}
	document.dispatchEvent(new Event("kategoria-achieved"));

	const container = document.getElementById('body_content');
	const productBox = document.createElement('div');
	productBox.setAttribute("class", "product-box");
	const on = document.createElement('div');
			on.setAttribute("class", "product-name");
			on.id = "on"+d.id;
			on.innerHTML = d.on;
			productBox.appendChild(on);
	const imgBox = document.createElement('div');
			imgBox.setAttribute("class", "images-box");
			let myImgs = (d.im)?d.im.split(';'):new Array("logo-kreativmp.gif");
			const imgMain = document.createElement('div');
			imgMain.setAttribute("class", "imgmain");
			const imgSlave = document.createElement('div');
			imgSlave.setAttribute("class", "imgslave");
			myImgs.forEach((im,i) => {
				if(i===0)
					imgMain.innerHTML += `<a href="https://www.domtest.ru/images/${im}" data-fancybox="gallery" class='aimg'>\n<img src='https://www.domtest.ru/images/${im}'>\n</a>\n`;
				else
					imgSlave.innerHTML += `<a href="https://www.domtest.ru/images/${im}" data-fancybox="gallery" class='aimg'>\n<img src='https://www.domtest.ru/images/${im}'>\n</a>\n`;
			});
		imgBox.appendChild(imgMain);
		imgBox.appendChild(imgSlave);
	productBox.appendChild(imgBox);
	const ds = document.createElement('div');
			ds.setAttribute("class", "product-description");
			ds.innerHTML = d.ds;
			productBox.appendChild(ds);
	const nz = document.createElement('div');
			nz.setAttribute("class", "product-description");
			nz.innerHTML = `<b>Назначение:</b> ${d.nz}`;
			productBox.appendChild(nz);
	const ob = document.createElement('div');
			ob.setAttribute("class", "product-description");
			ob.innerHTML = `<b>Объект исследования:</b> ${d.ob}`;
			productBox.appendChild(ob);
	const ch = document.createElement('div');
			ch.setAttribute("class", "product-description");
			ch.innerHTML = `<b>Чувствительность:</b> ${d.ch}`;
			productBox.appendChild(ch);
	if(d.kp){
		const kp = document.createElement('div');
			kp.setAttribute("class", "product-kp");
			kp.innerHTML = `<b>Комплектация:</b><blockquote>${d.kp}</blockquote>`;
			productBox.appendChild(kp);
	}
	const ru = document.createElement('div');
			ru.setAttribute("class", "product-description");
			ru.innerHTML = `<b>Регистрационное удостоверение:</b> ${d.ru}`;
			productBox.appendChild(ru);
	const pr = document.createElement('div');
			pr.setAttribute("class", "product-description");
			pr.innerHTML = `<b>Производитель:</b> ${d.pr}, ${d.pc}`;
			productBox.appendChild(pr);
	const price1 = document.createElement('div');
			price1.setAttribute("class", "product-price");
			price1.innerHTML = `<b>Цена:</b> ${d.price1} руб.`;
			productBox.appendChild(price1);
	const order = document.createElement('div');
			order.setAttribute("class", "product-description");
			order.innerHTML = ``;
			productBox.appendChild(order);
	
	  const myDivB = document.createElement('div');
		  myDivB.setAttribute("class", "product-divb");
		  
			  const myInput = document.createElement('input');
				myInput.type = 'text';
				myInput.value = 1;
				myInput.id = 'input-' + d.id;
				myInput.setAttribute('maxlength', '4');
				myInput.setAttribute('size', '2');
				myDivB.appendChild(myInput);
			  
			  const mySpan = document.createElement('span');
				mySpan.setAttribute("class", "product-span");
				mySpan.innerText = ' шт. ';
				myDivB.appendChild(mySpan);
				
			  const myButton = document.createElement('input');
				myButton.type = 'button';
				myButton.classList.add('cart-button');
				myButton.value = 'Заказать';
				myButton.setAttribute('data-addtobasketbutton',d.id);
				myDivB.appendChild(myButton);
				
	const myPrice1 = document.createElement('input');
	myPrice1.type = 'hidden';
	myPrice1.value = d.price1;
	myPrice1.id = 'price1-' + d.id;
	myDivB.appendChild(myPrice1);
  const myPrice2 = document.createElement('input');
	myPrice2.type = 'Hidden';
	myPrice2.value = d.price2;
	myPrice2.id = 'price2-' + d.id;
	myDivB.appendChild(myPrice2);
  const myPrice3 = document.createElement('input');
	myPrice3.type = 'Hidden';
	myPrice3.value = d.price3;
	myPrice3.id = 'price3-' + d.id;
	myDivB.appendChild(myPrice3);
  const myPrice4 = document.createElement('input');
	myPrice4.type = 'Hidden';
	myPrice4.value = d.price4;
	myPrice4.id = 'price4-' + d.id;
	myDivB.appendChild(myPrice4);
			
		  productBox.appendChild(myDivB);

	const myCheckMark = document.createElement('div');
			myCheckMark.classList.add('check-mark');
			//myCheckMark.addEventListener('basket_loaded', showCheckMarks(d.id));
			myCheckMark.setAttribute('data-id',d.id);
			showChecks(myCheckMark);
			productBox.appendChild(myCheckMark);
	
	container.appendChild(productBox);
}

const createListeners = () => {
		
	// обработка нажатия на кнопку Заказать на главной странице
  document.addEventListener('click', function(event) {
	if (event.target.dataset.addtobasketbutton != undefined) { 
		const id = Number(event.target.dataset.addtobasketbutton);
		let cnt = Number(document.getElementById('input-'+id).value);
		const on = document.getElementById('on'+id).textContent;
		const price1 = Number(document.getElementById('price1-'+id).value);
		const price2 = Number(document.getElementById('price2-'+id).value);
		const price3 = Number(document.getElementById('price3-'+id).value);
		const price4 = Number(document.getElementById('price4-'+id).value);
		loadBasket(id,cnt,on,price1,price2,price3,price4);
		return false;
	}
	if (event.target.dataset.mark != undefined) { 
		setTimeout(()=>{
			const b = document.getElementById('basket-header');
			if(!b.classList.contains('active'))
				b.click();
		}, 500);
	}
  });
  //Показываем или скрываем галочки
  document.addEventListener('basket_loaded', function(event) {
	const listChecks = document.getElementsByClassName('check-mark');
	for(let i=0;i<listChecks.length;i++){
		showChecks(listChecks[i]);
	}
  });
  
  
}
function showChecks(el){
	const ii = document.basket.findIndex(product => product.id === Number(el.dataset.id));
	const icon = `<svg enable-background="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g id="Cart"><g><path d="m26.064 18.807h-13.2l-.167-1.027h13.977c.476 0 .886-.336.98-.802l2.108-10.459c.059-.293-.017-.598-.206-.83s-.473-.367-.772-.368l-18.117-.057-.339-2.089c-.079-.484-.497-.84-.987-.84h-4.449c-1.383 0-2.509 1.122-2.509 2.501 0 1.378 1.125 2.5 2.509 2.5h.746l2.511 15.646c.078.485.496.842.987.842h16.929c1.369 0 2.482-1.126 2.482-2.51 0-1.382-1.114-2.507-2.483-2.507zm-14.218-6.278 3.363-.002.31 3.252h-3.146zm9.445-.005-.318 3.255h-3.445l-.31-3.253zm-4.264-1.998-.309-3.243 5.082.016-.315 3.225zm5.955 5.253.318-3.256 3.213-.002-.657 3.258zm3.935-5.258-3.421.001.313-3.218 3.754.012zm-12.209-3.244.31 3.25-3.497.002-.53-3.263zm11.356 14.547h-16.076l-2.51-15.646c-.078-.486-.497-.842-.988-.842h-1.598c-.281 0-.509-.224-.509-.5s.228-.501.509-.501h3.598l2.536 15.632c.079.484.497.84.987.84h14.051c.266 0 .482.228.482.508.001.281-.215.509-.482.509z"></path><path d="m12.88 24.144c-1.522 0-2.761 1.238-2.761 2.761s1.238 2.761 2.761 2.761 2.762-1.238 2.762-2.761-1.239-2.761-2.762-2.761zm0 3.521c-.419 0-.761-.341-.761-.761s.341-.761.761-.761.762.341.762.761-.342.761-.762.761z"></path><path d="m22.491 24.144c-1.523 0-2.762 1.238-2.762 2.761s1.239 2.761 2.762 2.761c1.522 0 2.761-1.238 2.761-2.761s-1.238-2.761-2.761-2.761zm0 3.521c-.42 0-.762-.341-.762-.761s.342-.761.762-.761c.419 0 .761.341.761.761s-.341.761-.761.761z"></path></g></g></svg>`;
	const dt = document.basket[ii];
		if(ii>=0){
			el.innerHTML = ` <a class="mark" data-mark href="#top">${icon}</a> <small>${dt.cnt}шт.по ${dt.price}р.</small>`;
		}else{
			el.innerHTML = ``;
		}	
}

//Ну, с Богом!
document.body.onload = loadData();